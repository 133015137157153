@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

html {
  scroll-behavior: smooth;
}
html,
body {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
